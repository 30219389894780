@import "../../../scss/style.scss";

.charitys-section {
  // .manageHeight {
  //   .upload__image-wrapper {
  //     height: auto;
  //   }
  // }
  .top-box {
    margin-bottom: 20px;
    h2 {
      text-align: left;
      margin-top: 10px;
      font-size: 25px;
      line-height: 1;
      color: #000;
    }
  }
  .rating-inner-box {
    .section-steps {
      width: 100%;
      .progressbar-box {
        display: none;
      }
      .btn-boxs {
        display: none;
      }
      .form-box {
        padding: 0;
      }
    }
    .form-area {
      .input-list {
        .col-div {
          .input-box {
            text-align: left;
          }
        }
      }
    }
    // .inner-div{
    //     margin-bottom: 50px;
    // }
    .div-checkbox {
      .inner-checkbox.average {
        width: 100%;
      }
    }
    .check-label {
      .checkmark {
        border-radius: 50%;
        width: 18px;
        height: 18px;
        &::after {
          left: 4px;
          top: 1px;
          width: 4px;
          height: 8px;
        }
      }
    }
  }
  .div-btn-box {
    .btn {
      width: auto;
      height: 33px;
      display: flex;
      align-items: center;
      border: 1px solid;
      padding: 5px 10px;
      border-radius: 5px;
      font-size: 14px;
      font-weight: 400;
      color: #389e0d;
      svg {
        width: 15px;
        height: 15px;
        margin-right: 7px;
      }
    }
  }
  .form-btn-box {
    margin-top: 25px;
  }
  .section-inner {
    background: #ffffff;
    @include prefix(box-shadow, 0px 2px 8px rgba(0, 0, 0, 0.15));
    padding: 25px 25px;
  }
  .status-box {
    span {
      min-width: 20px;
      border: 1px solid #86ddba;
      border-radius: 11px;
      padding: 3px 5px;
      color: #52c41a;
      text-transform: capitalize;
      line-height: 23px;
      &.reject {
        color: tomato;
        border-color: tomato;
      }
      &.pending {
        color: #2f54eb;
        border-color: #2f54eb;
      }
    }
  }
  .action-box {
    display: flex;
    padding: 0 15px;
    .btn {
      background-color: transparent;
      border: 1px solid #389e0d;
      width: 27px;
      height: 27px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      margin-right: 10px;
      cursor: pointer;
      &:last-child {
        margin-right: 0;
      }
      svg {
        width: 15px;
        height: 15px;
      }
      &.wrong {
        border-color: tomato;
        svg {
          fill: tomato;
        }
      }
      &.right {
        svg {
          fill: #389e0d;
        }
      }
    }
  }

  .form-area {
    .input-list {
      margin-bottom: 0px;
      .col-div {
        margin-bottom: 15px;
        &:last-child {
          margin-bottom: 0;
        }
        .form-grp {
          display: inline-block;
          width: 100%;
          vertical-align: top;
        }
        .input-box {
          height: 40px;
          width: 100%;
          font-family: "Jost",sans-serif;
          font-weight: 400;
          display: inline-block;
          line-height: 35px;
          padding: 15px 10px;
          border-radius: 5px;
          border: 1px solid #d9d9d9;
          &.text-area-box {
            height: 100px;
            resize: none;
            text-align: left;
            padding: 5px 10px;
          }
        }
      }
    }
    .select__placeholder {
      font-size: 13px;
    }
    .inner-div {
      margin-bottom: 0;
      div {
        margin-right: 10px;
        .check-label {
          &.reject {
            color: tomato;
            font-weight: 700;
            .checkmark {
              border-color: tomato;
            }
            input:checked ~ .checkmark {
              background-color: tomato;
            }
          }
          &.approve {
            color: #389e0d;
            font-weight: 700;
          }
        }
      }
    }
    .form-btn-box {
      justify-content: flex-end;
      .link-btn {
        width: 100px;
        margin-right: 20px;
        :last-child {
          margin-right: 0;
        }
        &.cancel {
          background-color: transparent;
          color: #389e0d;
          &:hover {
            background-color: #42a44c;
            color: #ffffff;
          }
        }
        &:hover {
          background-color: transparent;
          color: #389e0d;
        }
      }
    }
  }

  // toggle

  .switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 25px;
    input {
      opacity: 0;
      width: 0;
      height: 0;
      &:checked {
        + {
          .slider {
            background-color: #389e0d;
            &:before {
              -webkit-transform: translateX(23px);
              -ms-transform: translateX(23px);
              transform: translateX(23px);
            }
          }
        }
      }
      // &:focus {
      //     + {
      //         .slider{
      //             box-shadow: 0 0 1px #389E0D;
      //         }
      //     }
      // }
    }
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    &:before {
      position: absolute;
      content: "";
      height: 18px;
      width: 18px;
      left: 4px;
      bottom: 3px;
      background-color: white;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }
    &.rounds {
      border-radius: 34px;
      border-color: transparent;
      &:before {
        border-radius: 50%;
      }
    }
  }
}
