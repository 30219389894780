@import "../../scss/style.scss";

.strategic-section {
  .col-div {
    h2 {
      margin-top: 20px;
      font-size: 20px;
    }
  }
  .img-text {
    color: rgba(0, 0, 0, 0.45);
    font-weight: 400;
    font-size: 13px;
    display: block;
  }
  .profile-list-box {
    display: flex;
    align-items: center;
    .profile-list {
      li {
        display: inline-block;
        margin-left: -10px;
        position: relative;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: #fff;
        overflow: hidden;
        border: 3px solid #fff;
        &:first-child {
          margin-left: 0;
        }
        &:nth-child(n + 1) {
          //z-index: 1;
        }
      }
    }
    p {
      font-weight: 400;
      padding-left: 5px;
      padding-bottom: 5px;
    }
  }

  .status-box {
    span {
      min-width: 20px;
      border: 1px solid #86ddba;
      border-radius: 11px;
      padding: 3px 5px;
      color: #52c41a;
      text-transform: capitalize;
      line-height: 23px;
      &.reject {
        color: tomato;
        border-color: tomato;
      }
      &.pending {
        color: #2f54eb;
        border-color: #2f54eb;
      }
    }
  }
  .action-btn {
    display: flex;
    padding: 0 15px;
    .btn {
      background-color: transparent;
      border: 1px solid #389e0d;
      width: 25px;
      height: 25px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      margin-right: 10px;
      cursor: pointer;
      &:last-child {
        margin-right: 0;
      }
      svg {
        width: 15px;
        height: 15px;
      }
      &.wrong {
        border-color: tomato;
        svg {
          fill: tomato;
        }
      }
      &.right {
        svg {
          fill: #389e0d;
        }
      }
    }
  }
}
