@import "../../scss/style.scss";

.community-section {
  .col-div {
    h2 {
      margin-top: 20px;
      font-size: 20px;
    }
  }
  .status-box {
    span {
      min-width: 20px;
      border: 1px solid #86ddba;
      border-radius: 11px;
      padding: 3px 5px;
      color: #52c41a;
      text-transform: capitalize;
      line-height: 23px;
      &.reject {
        color: tomato;
        border-color: tomato;
      }
      &.pending {
        color: #2f54eb;
        border-color: #2f54eb;
      }
    }
  }
}

.communityHeader {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;


  & > div {
    width: 60%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .div-btn-box {
    width: 40%;
    justify-content: end;
  }
  .image-box {
    padding-top: 0;
  }
}

.img-text {
  color: rgba(0, 0, 0, 0.45);
  font-weight: 400;
  font-size: 13px;
  display: block;
}

.profile-list-box {
  display: flex;
  align-items: center;
  .profile-list {
    li {
      display: inline-block;
      margin-left: -10px;
      position: relative;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background-color: #fff;
      overflow: hidden;
      border: 3px solid #fff;
      &:first-child {
        margin-left: 0;
      }
    }
  }
  p {
    font-weight: 400;
    padding-left: 5px;
    padding-bottom: 5px;
  }
}

@media (max-width: 1400px) {
  .communityview-box {
    .image-box {
      .post-inner {
        .post-list {
          li {
            margin-right: 8px;
          }
        }
      }
    }
  }
}

@media (max-width: 1199px) {
  .communityHeader {
    & > div {
      width: 70%;
    }

    .div-btn-box {
      width: 30%;
    }
  }
}

@media (max-width: 768px) {
  .communityHeader {
    & > div {
      width: 60%;
    }

    .div-btn-box {
      width: 40%;
    }
  }
}

@media (max-width: 576px) {
  .communityHeader {
    margin-bottom: 15px;
    & > div {
      width: 100%;
    }

    .search-box {
      width: 100%;
      input {
        width: 100%;
      }
    }
    .image-box {
      ul {
        text-align: left;

        li {
          padding: 0 !important;
          font-size: 13px;
          &:first-of-type {
            margin-right: 14px;
          }
        }
      }
    }
    .div-btn-box {
      width: 100%;
      justify-content: start;
    }
  }
}
