@import "../../scss/style.scss";

.donor-section {
  .col-div {
    h2 {
      margin-top: 20px;
      font-size: 20px;
    }
  }
  .img-text {
    color: rgba(0, 0, 0, 0.45);
    font-weight: 400;
    font-size: 13px;
    display: block;
  }
  .profile-list-box {
    display: flex;
    align-items: center;
    .profile-list {
      li {
        display: inline-block;
        margin-left: -10px;
        position: relative;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: #fff;
        overflow: hidden;
        border: 3px solid #fff;
        &:first-child {
          margin-left: 0;
        }
      }
    }
    p {
      font-weight: 400;
      padding-left: 5px;
    }
  }
  .section-inner {
    background: #ffffff;
    @include prefix(box-shadow, 0px 2px 8px rgba(0, 0, 0, 0.15));
    padding: 25px 25px;
    width: 100%;
    display: inline-block;
  }
  .status-box {
    span {
      display: inline-block;
      font-size: 14px;
      letter-spacing: 1px;
      font-weight: 400;
      min-width: 20px;
      border: 1px solid #86ddba;
      border-radius: 11px;
      padding: 3px 5px;
      color: #52c41a;
      text-transform: capitalize;
      line-height: 23px;
      &.amount {
        color: #52c41a;
        border: 1px solid #86ddba;
        background-color: #f0fff9;
      }
      &.reject {
        color: tomato;
        border-color: tomato;
      }
      &.pending {
        color: #2f54eb;
        border-color: #2f54eb;
      }
    }
  }
  .top-areabox {
    margin-bottom: 20px;
    .search-box {
      margin-bottom: 0;
    }
  }
  .div-btn-box {
    display: flex;
    align-items: center;
    .date-lable {
      display: block;
      margin-bottom: 5px;
      color: #223047;
      font-size: 14px;
      font-weight: 400;
      text-transform: capitalize;
      line-height: 1;
    }
  }
  .form-area {
    .input-list {
      margin-bottom: 0px;
      .col-div {
        margin-bottom: 15px;
        &:last-child {
          margin-bottom: 0;
        }
        .form-grp {
          display: inline-block;
          width: 100%;
          vertical-align: top;
        }
        .input-box {
          height: 40px;
          width: 100%;
          font-family: "Jost",sans-serif;
          font-weight: 400;
          display: inline-block;
          line-height: 35px;
          padding: 15px 10px;
          border-radius: 5px;
          border: 1px solid #d9d9d9;
          text-align: left;
          &.text-area-box {
            height: 100px;
            resize: none;
            text-align: left;
            padding: 5px 10px;
            line-height: 21px;
          }
        }
      }
    }
    .select__placeholder {
      font-size: 13px;
    }
    .inner-div {
      margin-bottom: 0;
      div {
        margin-right: 10px;
        .check-label {
          &.reject {
            color: tomato;
            font-weight: 700;
            .checkmark {
              border-color: tomato;
            }
            input:checked ~ .checkmark {
              background-color: tomato;
            }
          }
          &.approve {
            color: #389e0d;
            font-weight: 700;
          }
        }
      }
    }
    .form-btn-box {
      justify-content: flex-end;
      .link-btn {
        width: 100px;
        margin-right: 20px;
        :last-child {
          margin-right: 0;
        }
        &.cancel {
          background-color: transparent;
          color: #389e0d;
          &:hover {
            background-color: #42a44c;
            color: #ffffff;
          }
        }
        &:hover {
          background-color: transparent;
          color: #389e0d;
        }
      }
    }
  }
  .subHeaderWrap {
    display: flex;
    justify-content: space-between;
  }
  .image-box {
    padding-top: 0;
  }
}

@media (max-width: 768px) {
  .donor-section {
    .subHeaderWrap {
      flex-wrap: wrap;

      .search-box {
        margin-right: 15px;
        li {
          font-size: 12px !important;
        }
      }
    }
  }
}

@media (max-width: 575px) {
  .donor-section {
    .search-box {
      width: 100%;
      margin-right: 0 !important;
      .search-input {
        width: 100%;
      }
    }
    .image-box {
      ul {
        text-align: left;

        li {
          padding: 0 !important;
          font-size: 13px;
          &:first-of-type {
            margin-right: 14px;
          }
        }
      }
    }
  }
}
