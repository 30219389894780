@import "../../../scss/style.scss";

.model-area{
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: none;
    align-items: center;
    justify-content: center;
    z-index: 11111;
    &::before{
        content: '';
        background-color: rgba(0,0,0,0.5);
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
    }
    &.open-model{
        display: flex;
    }
}
.model-box{
    margin-top: 20px;
    background: #FFFFFF;
    padding: 25px 0px 15px;
    width: 500px;
    .modele-text-box{
        padding: 0 25px;
        h2{
            font-size: 22px;
            text-align: left;
            line-height: 30px;
        }
        p{
            margin: 15px 0;
            font-weight: 400;
        }
    }
    .inner-checkbox{
        width: 100%;
    }
    .input-box{
        text-align: left;
        &.text-area-box{
            height: 100px;
            resize: none;
            text-align: left;
            padding: 5px 10px;
        }
    }
}
.model-btn{
    text-align: right;
    border-top: 1px solid rgba(0,0,0,0.15);
    padding-top: 15px;
    .btn-box{
        background-color: #389E0D;
        border: 1px solid #389E0D;
        color: #fff;
        padding: 5px 10px;
        font-size: 15px;
        min-width: 70px;
        height: 40px;
        display: inline-block;
        margin-right: 20px;
        border-radius: 5px;
        cursor: pointer;
        // &:last-child{
        //     margin-right: 0;
        // }
        &.no{
            background-color: transparent;
            color: #389E0D;
        }
    }
}