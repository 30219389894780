.section-inner {
  background: #ffffff;
  @include prefix(box-shadow, 0px 2px 8px rgba(0, 0, 0, 0.15));
  padding: 25px 25px 75px;
  width: 100%;
  min-height: calc(100vh - 198px);
  height: 100%;
  display: inline-block;
  position: relative;
}
.position-box {
  position: absolute;
  right: 20px;
  bottom: 20px;
}
.top-box {
  margin-bottom: 20px;
  h2 {
    text-align: left;
    margin-top: 10px;
    font-size: 25px;
    line-height: 1;
    color: #000;
    height: auto;
  }
}
.formatText {
  text-transform: none !important;
}
.rdt_TableHeadRow {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.rdt_TableCell {
  font-size: 14px;
  font-weight: 400;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
  &:first-of-type {
    border-left: 1px solid rgba(0, 0, 0, 0.12);
  }
}
.rdt_TableCol {
  font-size: 15px;
  font-weight: 700;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
  &:first-of-type {
    border-left: 1px solid rgba(0, 0, 0, 0.12);
  }
}

.label-text {
  display: block;
  margin-bottom: 10px;
  color: #223047;
  font-size: 14px;
  font-weight: 400;
  text-transform: capitalize;
  line-height: 20px;
  span {
    color: red;
    display: inline-block;
    margin-right: 5px;
  }
}

.div-btn-box {
  display: flex;
  flex-wrap: wrap;
  .btn {
    width: auto;
    height: 33px;
    display: flex;
    align-items: center;
    border: 1px solid;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 400;
    color: #389e0d;
    background-color: transparent;
    margin-right: 15px;
    cursor: pointer;
    &:last-child {
      margin-right: 0;
    }
    svg {
      width: 15px;
      height: 15px;
      margin-right: 7px;
    }
  }
}
.status-box {
  span {
    min-width: 20px;
    border: 1px solid #86ddba;
    border-radius: 11px;
    padding: 3px 5px;
    color: #52c41a;
    text-transform: capitalize;
    line-height: 23px;
    &.reject {
      color: tomato;
      border-color: tomato;
    }
    &.pending {
      color: #2f54eb;
      border-color: #2f54eb;
    }
  }
}
.search-box {
  margin-bottom: 20px;
  .search-input {
    border: 1px solid rgba(0, 0, 0, 0.12);
    width: 250px;
    height: 33px;
    display: inline-block;
    font-weight: 400;
    border-radius: 5px;
    padding: 10px 10px;
  }
}

.search-input {
  border: 1px solid rgba(0, 0, 0, 0.12);
  width: 250px;
  height: 33px;
  display: inline-block;
  font-weight: 400;
  border-radius: 5px;
  padding: 10px 10px;
}

.action-box {
  display: flex;
  padding: 0 15px;
  .btn {
    background-color: transparent;
    border: 1px solid #389e0d;
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin-right: 10px;
    cursor: pointer;
    &:last-child {
      margin-right: 0;
    }
    svg {
      width: 15px;
      height: 15px;
    }
    &.wrong {
      border-color: tomato;
      svg {
        fill: tomato;
      }
    }
    &.right {
      svg {
        fill: #389e0d;
      }
    }
  }
}

.action-btn {
  .btn {
    background-color: transparent;
    border: 1px solid #389e0d;
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin-right: 10px;
    cursor: pointer;
    &:last-child {
      margin-right: 0;
    }
    svg {
      width: 15px;
      height: 15px;
    }
    &.wrong {
      border-color: tomato;
      svg {
        fill: tomato;
      }
    }
    &.right {
      svg {
        fill: #389e0d;
      }
    }
  }
}

.input-box {
  height: 40px;
  width: 100%;
  font-family: "Jost",sans-serif;
  font-weight: 400;
  display: inline-block;
  line-height: 35px;
  padding: 15px 10px;
  border-radius: 5px;
  border: 1px solid #d9d9d9;
  text-align: left;
}

.link-btn {
  display: inline-block;
  width: 100%;
  height: 40px;
  text-transform: capitalize;
  line-height: 40px;
  text-align: center;
  background-color: $theme-color;
  color: $white;
  @include prefix(box-shadow, 0px 2px 0px rgba(0, 0, 0, 0.043));
  @include prefix(transition, all 0.4s ease-in-out);
  border: 1px solid #42a44c;
  border-radius: 5px !important;
  font-weight: 400;
  font-size: 16px;
  cursor: pointer;
  &.back {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px;
    height: 100%;
  }
  &:hover {
    background-color: transparent;
    color: #42a44c;
  }
}

.heart {
  background-color: gainsboro;
  display: inline-block;
  height: 13px;
  margin: 0 10px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transform: rotate(-45deg);
  width: 13px;
  cursor: pointer;
  &::before,
  &::after {
    content: "";
    background-color: gainsboro;
    border-radius: 50%;
    height: 13px;
    position: absolute;
    width: 13px;
  }
  &:before {
    top: -7px;
    left: 0;
  }
  &:after {
    left: 7px;
    top: 0;
  }
  &.fill {
    background-color: red;
    &::before,
    &::after {
      background-color: red;
    }
  }
}
.form-area {
  .input-list {
    margin-bottom: 0px;
    .col-div {
      margin-bottom: 15px;
      &:last-child {
        margin-bottom: 0;
      }
      .form-grp {
        display: inline-block;
        width: 100%;
        vertical-align: top;
      }
      .input-box {
        height: 40px;
        width: 100%;
        font-family: "Jost",sans-serif;
        font-weight: 400;
        display: inline-block;
        line-height: 35px;
        padding: 15px 10px;
        border-radius: 5px;
        border: 1px solid #d9d9d9;
        text-align: left;
        &.text-area-box {
          height: 100px;
          resize: none;
          text-align: left;
          padding: 5px 10px;
          line-height: 21px;
        }
      }
    }
  }
  .select__placeholder {
    font-size: 13px;
  }
  .inner-div {
    margin-bottom: 0;
    div {
      margin-right: 10px;
      .check-label {
        &.reject {
          color: tomato;
          font-weight: 700;
          .checkmark {
            border-color: tomato;
          }
          input:checked ~ .checkmark {
            background-color: tomato;
          }
        }
        &.approve {
          color: #389e0d;
          font-weight: 700;
        }
      }
    }
  }
  .form-btn-box {
    margin-top: 10px;
    justify-content: flex-end;
    .link-btn {
      width: 100px;
      margin-right: 20px;
      :last-child {
        margin-right: 0;
      }
      &.cancel {
        background-color: transparent;
        color: #389e0d;
        &:hover {
          background-color: #42a44c;
          color: #ffffff;
        }
      }
      &:hover {
        background-color: transparent;
        color: #389e0d;
      }
    }
  }
}

.img-text {
  color: rgba(0, 0, 0, 0.45);
  font-weight: 400;
  font-size: 13px;
  display: block;
}
// check-box
.check-label {
  display: block;
  position: relative;
  padding-left: 25px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.85);
  @include prefix(user-select, none);

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    &:checked {
      ~ .checkmark:after {
        display: block;
      }
      ~ .checkmark {
        background-color: $theme-color;
      }
    }
  }

  .checkmark {
    &:after {
      left: 3px;
      top: 0px;
      width: 4px;
      height: 8px;
      border: solid white;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
      @include prefix(transform, rotate(45deg));
    }
  }
}
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  border-radius: 2px;
  background-color: transparent;
  border: 1px solid $theme-color;
  overflow: hidden;
  &::after {
    content: "";
    position: absolute;
    display: none;
  }
}

// module

.model-area {
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: none;
  align-items: center;
  justify-content: center;
  z-index: 11111;
  &::before {
    content: "";
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }
  &.open-model {
    display: flex;
  }
}
.close-btn {
  font-size: 25px;
  font-family: "Jost",sans-serif;
  font-weight: 400;
  background-color: transparent;
  color: #389e0d;
  cursor: pointer;
}
.member-view-model {
  .model-box {
    width: 700px;
    padding: 25px 0;
    .modele-text-box {
      padding: 0;
      .main-title {
        padding: 0 15px 15px 15px;
        border-bottom: 1px solid #f0f0f0;
      }
    }
  }
}
.model-body {
  padding: 0 25px;
  .box-area {
    display: flex;
    align-items: center;
    .box-img {
      width: 102px;
      height: 50px;
      display: inline-block;
      margin-right: 20px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    h5 {
      font-weight: 600;
      font-size: 15px;
      color: #000;
    }
  }
  .model-box-list {
    li {
      display: inline-block;
      width: 48%;
      vertical-align: top;
      margin-right: 20px;
      margin-bottom: 30px;
      padding: 20px 10px;
      @include prefix(box-shadow, 0px 4px 15px rgba(0, 0, 0, 0.25));
      border-radius: 8px;
      overflow: hidden;
      &:last-child {
        margin-bottom: 0;
        margin-right: 0;
      }
      &:nth-last-child(2) {
        margin-bottom: 0;
      }
      &:nth-child(2n) {
        margin-right: 0;
      }
    }
  }
}
.model-title-box {
  position: relative;
  padding: 25px 0;
  h4 {
    color: #000;
    font-size: 27px;
    font-weight: 500;
    position: relative;
    display: flex;
    align-items: center;
  }
  span {
    background-color: #389e0d;
    color: #fff;
    font-size: 15px;
    border: 1px solid #40a9ff;
    border-radius: 15px;
    width: 41px;
    height: 21px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 13px;
  }
}

.model-box {
  background: #ffffff;
  padding: 25px 0px 15px;
  width: 530px;
  margin: 20px 10px 0;
  .modele-text-box {
    padding: 0 25px;
    h2 {
      font-family: "Jost",sans-serif;
      font-size: 25px;
      text-align: left;
      line-height: 30px;
    }
    p {
      margin: 15px 0;
      font-weight: 400;
    }
    .input-list {
      li {
        ::-webkit-input-placeholder {
          text-align: left;
        }
        :-moz-placeholder {
          text-align: left;
        }
        ::-moz-placeholder {
          text-align: left;
        }
        :-ms-input-placeholder {
          text-align: left;
        }
      }
    }
  }
  .inner-checkbox {
    width: 100%;
  }
  .input-box {
    text-align: left;
    &.text-area-box {
      height: 100px !important;
      resize: none;
      text-align: left;
      padding: 5px 10px !important;
    }
  }
}
.model-btn {
  text-align: right;
  border-top: 1px solid rgba(0, 0, 0, 0.15);
  padding-top: 15px;
  .btn-box {
    background-color: #389e0d;
    border: 1px solid #389e0d;
    color: #fff;
    padding: 5px 10px;
    font-size: 15px;
    min-width: 70px;
    height: 40px;
    font-family: "Jost",sans-serif;
    display: inline-block;
    margin-right: 20px;
    border-radius: 5px;
    cursor: pointer;
    &.no {
      background-color: transparent;
      color: #389e0d;
    }
    &:last-child {
      margin-right: 0;
    }
  }
}

// comment-section
.comment-section {
  margin-top: 15px;
  .comment-sectionList {
    //height: 291px;
    overflow: hidden;
  }

  .read-commet-section {
    height: auto;
  }
}
.comment-parent {
  margin-bottom: 20px;
  &:last-of-type {
    margin-bottom: 0;
  }
}

.comment-bar {
  display: flex;
  flex-wrap: wrap;
  border-radius: 7px;
  padding: 0;
  .innerbox-div {
    width: 100%;
    background-color: #fff;
    padding: 10px 20px;
    // margin-bottom: 10px;
  }
  figure {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    //border-radius: 50%;
    flex-shrink: 0;
    position: relative;
    overflow: hidden;
    &.hearts {
      svg {
        width: 25px;
        height: 23px;
        transform: scale(1);
        animation: bubble 0.3s;
        path {
          fill: red;
          stroke: red;
        }
      }
      @keyframes bubble {
        0% {
          transform: scale(0.7);
        }
        100% {
          transform: scale(1.3);
        }
      }
    }
    img {
      width: 100%;
      height: 100%;
      background-color: transparent;
      border-radius: 50%;
    }
  }
}

.comment-wrap {
  margin-left: 12px;
  p {
    font-size: 10px;
  }

  .commentPersonName {
    //   font-family: $font_jost;
    font-weight: 700;
    font-size: 14px;
    //   color: $light-gray;
    text-transform: capitalize;
    letter-spacing: 0.004em;
    line-height: 18px;
  }
  .comment-text {
    //   font-family: $font_jost;
    font-weight: 400;
    font-size: 12px;
    //   color: $light-gray;
    line-height: 14px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;

    span {
      font-size: 14px;
      font-weight: 700;
      // color: $green-shadow;
      cursor: pointer;
    }
  }
  .commentShow {
    //   font-family: $font_jost;
    font-weight: 400;
    font-size: 12px;
    //   color: $light-gray;
    line-height: 14px;
    span {
      font-size: 14px;
      font-weight: 700;
      // color: $green-shadow;
      cursor: pointer;
    }
  }
}

.comment-child {
  margin-left: 55px;
  margin-top: 20px;

  .comment-child-list {
    margin-bottom: 20px;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

.postStart {
  padding-left: 10px;
}

.postCommentAction {
  display: flex;
  width: 100%;
  // margin-top: 10px;
  flex-wrap: wrap;
  p {
    margin-bottom: 10px;
    display: block;
    line-height: 1;
  }
  div {
    margin-right: 20px;
    line-height: 1;
    span {
      // font-family: $font_jost;
      font-weight: 400;
      font-size: 13px !important ;
      line-height: 1;
      margin-bottom: 0 !important;
      margin-left: 5px;
      cursor: pointer;
      // color: $gray;
    }
  }
}

.replyBox {
  display: flex;
  background-color: $white;
  border-radius: 7px;
  padding: 9px 14px 9px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
  margin-top: 15px;
  margin-left: 55px;

  figure {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 12px;

    img {
      width: 100%;
      height: 100%;
      background-color: transparent;
      border-radius: 50%;
    }
  }

  .form-group {
    display: flex;
    textarea {
      width: 445px;
      height: 85px;
      background: transparent;
      resize: none;
      border: 1px solid rgba($color: $black, $alpha: 0.12);
      border-radius: 16px;
      padding: 10px;
      // font-family: $font_jost;
      font-weight: 400;
      font-size: 14px;
      margin-right: 10px;
      color: #666666;
    }

    .commentReplyButton {
      width: 100px;
      max-height: 35px;
      cursor: pointer;
      // background-color: $green-shadow;
      // font-family: $font_jost;
      font-weight: 400;
      color: $white;
      font-size: 14px;
      border-radius: 25px;
      @include prefix(transition, all 0.3s ease-in-out);

      &:hover {
        background-color: $theme-color;
      }
    }
  }
}

.mailingLine{
  margin-top: 20px;
}

.postCommentSection {
  width: 100%;
  // height: 712px;
  height: 488px;

  .content {
    padding: 6px;
    background: transparent;
    width: 100%;
    height: auto;
  }
}
// toggle

.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 25px;
  input {
    opacity: 0;
    width: 0;
    height: 0;
    &:checked {
      + {
        .slider {
          background-color: #389e0d;
          &:before {
            -webkit-transform: translateX(23px);
            -ms-transform: translateX(23px);
            transform: translateX(23px);
          }
        }
      }
    }
    // &:focus {
    //     + {
    //         .slider{
    //             box-shadow: 0 0 1px #389E0D;
    //         }
    //     }
    // }
  }
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  &:before {
    position: absolute;
    content: "";
    height: 18px;
    width: 18px;
    left: 4px;
    bottom: 3px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
  &.rounds {
    border-radius: 34px;
    border-color: transparent;
    &:before {
      border-radius: 50%;
    }
  }
}

// responsive

@media (max-width: 1199px) {
  .top-areabox {
    align-items: flex-end;
  }
  .search-box {
    .search-input {
      width: 200px;
    }
  }
  .search-input {
    width: 200px;
  }
  .section-inner {
    height: 100%;
  }
}

// .notification-inner{
//   min-height: calc(100vh - 500px) !important;
// }
.notification-inner{
  height: auto !important;
}

@media (max-width: 991px) {
  .top-areabox {
    flex-wrap: wrap;
    .div-btn-box {
      width: 100%;
      margin-bottom: 20px;
      order: 1;
    }
    .search-box {
      order: 2;
    }
  }
}

@media (max-width: 767px) {
  .form-area {
    .input-list {
      .col-div {
        padding-left: 0;
      }
    }
  }
  .section-inner {
    padding: 20px 15px 85px;
  }
}

@media (max-width: 575px) {
  .check-label {
    font-size: 13px;
  }
  .top-areabox {
    .div-btn-box {
      flex-wrap: wrap;
      margin-bottom: 13px;
      div {
        margin-bottom: 3px;
        width: 100%;
      }
      span {
        font-size: 0;
        display: none;
      }
    }
  }
  .top-box {
    margin-bottom: 15px;
    h2 {
      font-size: 17px;
    }
  }
  .search-box {
    .search-input {
      width: 130px;
    }
  }
  .search-input {
    width: 100%;
  }
  .section-inner {
    padding: 20px 15px 85px;
  }
  .model-box {
    width: 295px;
  }
}
