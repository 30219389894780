@import "../../../scss/style.scss";

.breadcrumb-ul {
  li {
    display: inline-block;
    position: relative;
    a {
      color: rgba(0, 0, 0, 0.45);
      font-size: 14px;
      font-weight: 400;
      display: inline-block;
      h4 {
        font-size: 14px;
        font-weight: 400;
      }
    }
    &.active {
      color: rgba(0, 0, 0, 0.85);
    }
    &:after {
      content: "/";
      margin: 0 10px;
      color: rgba(0, 0, 0, 0.45);
    }
    &:last-child {
      &:after {
        display: none;
      }
    }
    .mainPage {
      color: #42a44c;
      font-size: 14px;
      font-weight: 400;
      line-height: 1;
    }
  }
}

@media (max-width: 575px) {
  .breadcrumb-ul {
    li {
      a {
        font-size: 13px;
      }
    }
  }
}
