@import "../../scss/style.scss";

.section-div {
  min-height: 100vh;
}

.mainLogo {
  display: flex;
  justify-content: center;
  text-align: center;
  margin-bottom: 30px;
}

.pwd-section {
  .input-list {
    margin-bottom: 22px;
    li {
      margin-bottom: 28px;
      &:last-child {
        margin-bottom: 0;
      }
      .form-grp {
        display: inline-block;
        width: 100%;
        vertical-align: top;
      }
      .input-box {
        height: 40px;
        width: 100%;
        font-family: "Jost",sans-serif;
        font-weight: 400;
        display: inline-block;
        line-height: 35px;
        padding: 10px 20px;
        border-radius: 5px;
        border: 1px solid #d9d9d9;
      }
    }
  }

  .bottom-text {
    text-align: center;
    display: block;
    color: $theme-color;
    font-weight: 400;
    font-size: 14px;
    margin-top: 35px;
    a {
      display: inline-block;
      font-weight: 700;
      color: $theme-color;
    }
  }

  .link-btn {
    display: inline-block;
    width: 100%;
    height: 40px;
    line-height: 40px;
    text-align: center;
    background-color: $theme-color;
    color: $white;
    @include prefix(box-shadow, 0px 2px 0px rgba(0, 0, 0, 0.043));
    @include prefix(transition, all 0.4s ease-in-out);
    border: 1px solid #42a44c;
    border-radius: 2px;
    font-weight: 400;
    font-size: 16px;
    &:hover {
      background-color: transparent;
      color: #42a44c;
    }
  }
  .inner-div {
    margin-bottom: 25px;
  }

  .form-box {
    padding: 0;
    width: 400px;
    margin: 0 auto;
    .signHeader {
      display: inline-block;
      width: 100%;
      font-size: 28px;
      color: rgba(66, 164, 76, 0.85);
      margin-bottom: 20px;
      font-weight: 700;
      text-align: center;
    }
  }

  .figure-img {
    width: 425px;
    height: 450px;
    display: block;
    margin: auto;
  }
}

// check-box
.check-label {
  display: block;
  position: relative;
  padding-left: 25px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.85);
  @include prefix(user-select, none);

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    &:checked {
      ~ .checkmark:after {
        display: block;
      }
      ~ .checkmark {
        background-color: $theme-color;
      }
    }
  }

  .checkmark {
    &:after {
      left: 3px;
      top: 0px;
      width: 4px;
      height: 8px;
      border: solid white;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
      @include prefix(transform, rotate(45deg));
    }
  }
}
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  border-radius: 2px;
  background-color: transparent;
  border: 1px solid $theme-color;
  overflow: hidden;
  &::after {
    content: "";
    position: absolute;
    display: none;
  }
}

@media (max-width: 1400px) {
  .pwd-section {
    .form-box {
      width: 380px;
      .signHeader {
        font-size: 26px;
        margin-bottom: 18px;
      }
    }
  }
}

@media (max-width: 1199px) {
  .pwd-section {
    .form-box {
      width: 350px;
      .signHeader {
        font-size: 24px;
        margin-bottom: 16px;
      }
    }
  }
}

@media (max-width: 992px) {
  .pwd-section {
    .figure-img {
      width: 350px;
      height: 370px;
    }
    .form-box {
      width: 320px;
      .signHeader {
        font-size: 22px;
        margin-bottom: 15px;
      }
    }
  }
}

@media (max-width: 767px) {
  .pwd-section {
    .none-mobile {
      display: none;
    }
    .form-box {
      padding: 0;
    }
    .input-list li {
      margin-bottom: 15px;
    }
  }
}

@media (max-width: 575px) {
  .pwd-section {
    .form-box {
      width: 290px;
    }
  }
}
